// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/golos/GolosText-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/golos/GolosText-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/golos/GolosText-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/golos/GolosText-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/golos/GolosText-ExtraBold.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../fonts/golos/GolosText-Black.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-display:swap;font-family:\"Golos\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}@font-face{font-display:swap;font-family:\"Golos\";font-style:normal;font-weight:500;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}@font-face{font-display:swap;font-family:\"Golos\";font-style:normal;font-weight:600;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")}@font-face{font-display:swap;font-family:\"Golos\";font-style:normal;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\")}@font-face{font-display:swap;font-family:\"Golos\";font-style:normal;font-weight:800;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\")}@font-face{font-display:swap;font-family:\"Golos\";font-style:normal;font-weight:900;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\")}:root{--primary-color:#1490e9;--background-color:#f5f8fe;--border-color:#ecf1fe;--border-color-gray:#c0c6cf;--title-text-color:#303236;--error-color:#ff4e55;--bright-text-color:rgba(19,39,62,.6);--border-radius-13:13px;--border-radius-8:8px}body{scrollbar-gutter:stable}.indent{margin-left:15px;margin-right:15px}.page-top{display:flex;flex-direction:column;min-height:100vh}.hidden{display:none!important}*{box-sizing:border-box;font-family:\"Golos\",sans-serif;margin:0;padding:0}.center{display:flex;justify-content:center}a{color:#1490e9;color:var(--primary-color);-webkit-text-decoration:none;text-decoration:none}input{outline:none}button{cursor:pointer}ol{padding-left:20px;text-indent:-20px}ol,ul{list-style-position:inside}ul{padding-left:25px;text-indent:-25px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
