import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _414e7274 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _3ddcc717 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _18ae705e = () => interopDefault(import('../pages/articlesSkeletPage.vue' /* webpackChunkName: "pages/articlesSkeletPage" */))
const _6cf99b41 = () => interopDefault(import('../pages/finefound.vue' /* webpackChunkName: "pages/finefound" */))
const _45f42420 = () => interopDefault(import('../pages/finenotfound.vue' /* webpackChunkName: "pages/finenotfound" */))
const _107eac7f = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _21af6db5 = () => interopDefault(import('../pages/payment-success.vue' /* webpackChunkName: "pages/payment-success" */))
const _acab520e = () => interopDefault(import('../pages/paymentGuarantee.vue' /* webpackChunkName: "pages/paymentGuarantee" */))
const _5910dd36 = () => interopDefault(import('../pages/paymenthistory.vue' /* webpackChunkName: "pages/paymenthistory" */))
const _061914b1 = () => interopDefault(import('../pages/requisites.vue' /* webpackChunkName: "pages/requisites" */))
const _51d6f753 = () => interopDefault(import('../pages/savedcards.vue' /* webpackChunkName: "pages/savedcards" */))
const _1366216c = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _5c33fdd0 = () => interopDefault(import('../pages/searchTest/fns/index.vue' /* webpackChunkName: "pages/searchTest/fns/index" */))
const _01debcea = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5a728c5c = () => interopDefault(import('../pages/articles/_alias.vue' /* webpackChunkName: "pages/articles/_alias" */))
const _4021540c = () => interopDefault(import('../pages/search/_type/index.vue' /* webpackChunkName: "pages/search/_type/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _414e7274,
    name: "about"
  }, {
    path: "/articles",
    component: _3ddcc717,
    name: "articles"
  }, {
    path: "/articlesSkeletPage",
    component: _18ae705e,
    name: "articlesSkeletPage"
  }, {
    path: "/finefound",
    component: _6cf99b41,
    name: "finefound"
  }, {
    path: "/finenotfound",
    component: _45f42420,
    name: "finenotfound"
  }, {
    path: "/payment",
    component: _107eac7f,
    name: "payment"
  }, {
    path: "/payment-success",
    component: _21af6db5,
    name: "payment-success"
  }, {
    path: "/paymentGuarantee",
    component: _acab520e,
    name: "paymentGuarantee"
  }, {
    path: "/paymenthistory",
    component: _5910dd36,
    name: "paymenthistory"
  }, {
    path: "/requisites",
    component: _061914b1,
    name: "requisites"
  }, {
    path: "/savedcards",
    component: _51d6f753,
    name: "savedcards"
  }, {
    path: "/unsubscribe",
    component: _1366216c,
    name: "unsubscribe"
  }, {
    path: "/searchTest/fns",
    component: _5c33fdd0,
    name: "searchTest-fns"
  }, {
    path: "/",
    component: _01debcea,
    name: "index"
  }, {
    path: "/articles/:alias",
    component: _5a728c5c,
    name: "articles-alias"
  }, {
    path: "/search/:type",
    component: _4021540c,
    name: "search-type"
  }],

  parseQuery: (query) => require('qs').parse(query),
  stringifyQuery: (query) => {
    const result = require('qs').stringify(query);
    return result ? '?' + result : '';
  },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
